import { computed } from 'vue';
import { useStore } from 'vuex';
import constants from '@/constants/constants';

export default function useOptiMunichPromotionComposable()
{
    // ------------------------------------------------------------ CONSTANTS

    const ARTICLE_CODE = 'SL_OPTI_TICKET';
    const YEAR = 2025;


    // ------------------------------------------------------------ COMPOSABLES

    const store = useStore();


    // ------------------------------------------------------------ COMPUTED

    const hasOptiTicket = computed(() =>
    {
        const articleStatus = store.getters['catalog/getByCode'](ARTICLE_CODE)?.status;
        if(!articleStatus)
        {
            return false;
        }

        return articleStatus !== constants.articles.status.INACTIVE;
    });

    const hasOptiTicketInBasket = computed(() =>
        store.getters['basket/getLinesByArticleCode'](ARTICLE_CODE).length > 0
    );


    // ------------------------------------------------------------ EXPORT

    return {
        // Constants
        ARTICLE_CODE,
        YEAR,

        // Computed
        hasOptiTicket,
        hasOptiTicketInBasket,
    };
};

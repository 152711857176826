import AbstractSurfaceFilter from './AbstractSurfaceFilter';
import Objects from '@/utils/Objects';

/**
 * @typedef {import('@/views/order/lenses/fields/custom/PlotGraphButton.vue').Point} Point
 */

export default class OffsetFilter extends AbstractSurfaceFilter
{
    offset = 0;

    constructor(options = {})
    {
        super(options);
        this.offset = options.offset;
    }

    /**
     * Run the filter.
     *
     * @param {Point[]} points
     * @returns {Point[]}
     */
    run(points)
    {
        return points.map(
            ([x, y]) => [x, y + this.offset]
        );
    }
}

<!-- /////////////////////////////////////////////////////////////////////////// TEMPLATE -->

<template>
    <div class="sl-order-form__line-side" :class="'sl-order-form__line-side-' + side">
        <!-- Helper -->
        <div class="sl-order-form__line-band-wrapper">
            <div class="sl-order-form__line-band-content"></div>
        </div>

        <!-- Error Wrapper -->
        <div class="sl-order-form__line-content-wrapper">
            <!-- Field Wrapper -->
            <div class="sl-order-form__field-wrapper">
                <!-- Field -->
                <div class="sl-order-form__graph">
                    <div
                        class="lsn-btn lsn-btn--fitting"
                        :class="`lsn-btn--fitting-${fittings.current[side]}`"
                        :tabindex="tabIndex"
                        @click.prevent="openGraph"
                        @keypress.enter="openGraph"
                    >
                        <mdi-icon icon="mdiChartBellCurve" class="inline-block w-5 h-5" />

                        <span class="inline-block ml-2">
                            {{ $t('parameters.PLOT_GRAPH_BUTTON.button_label') }}

                            <template v-if="graphOpen">
                                /
                                {{ $t('common.refresh') }}
                            </template>
                        </span>

                        <span v-if="fittings[side].length > 2" class="ml-1">
                            ({{ getLensLetter(fittings.current[side]) }})
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Graph Window -->
        <teleport to="body">
            <lsn-sider :is-open="graphOpen" :side="side" class="sl-graph">
                <div class="sl-graph__content">
                    <!-- Title -->
                    <div
                        class="mb-8 h-8 text-primary-500 text-2xl font-thin"
                        :class="side === 'left' ? 'pl-12' : 'pr-12'"
                    >
                        {{ $t('plot_graph.window.title') }}
                    </div>

                    <!-- Close Buttton -->
                    <div>
                        <mdi-icon
                            icon="mdiClose"
                            class="sl-graph__close"
                            :class="`sl-graph__close--${side}`"
                            tabindex="0"
                            @click="graphOpen = false"
                        />
                    </div>

                    <div class="flex gap-2 mb-2">
                        <!-- Fitting Selector -->
                        <div class="sl-graph__fittings">
                            <template
                                v-for="(fitting, id) in fittings[side]"
                                :key="id"
                            >
                                <div
                                    v-if="fitting"
                                    class="cursor-pointer flex items-center rounded-sm select-none outline-none
                                        focus-visible:ring-1 focus-visible:ring-primary-500"
                                    tabindex="0"
                                    @click="toggleDrawing(side, id)"
                                    @keypress.enter="toggleDrawing(side, id)"
                                >
                                    <div>
                                        <mdi-icon
                                            v-if="selectedForDrawing.includes(id)"
                                            icon="mdiCheckboxMarked"
                                            class="w-5 h-5"
                                            :style="{ color: zoneColors[id]['OPTICAL']}"
                                        />

                                        <mdi-icon
                                            v-else
                                            icon="mdiCheckboxBlankOutline"
                                            class="w-5 h-5"
                                        />
                                    </div>

                                    <div class="ml-1 truncate">
                                        {{ $t('common.lens') }}
                                        {{ getLensLetter(id) }}
                                    </div>
                                </div>
                            </template>
                        </div>

                        <!-- Filters -->
                        <!-- todo: remove v-if when at least one filter doesn't depend on multiple lenses -->
                        <div v-if="selectedForDrawing.length > 1" class="sl-graph__filters">
                            <!-- Offset Filter -->
                            <div
                                class="flex items-center rounded-sm select-none outline-none
                            focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-primary-500"
                                :class="{
                                    'cursor-not-allowed': selectedForDrawing.length <= 1,
                                    'text-gray-400': selectedForDrawing.length <= 1,
                                    'cursor-pointer': selectedForDrawing.length > 1,
                                }"
                                tabindex="0"
                                @click="toggleBackSurfaceOffset"
                                @keypress.enter="toggleBackSurfaceOffset"
                            >
                                <div>
                                    <mdi-icon
                                        :icon="backSurfaceFilters.offset ? 'mdiCheck' : 'mdiCheckboxBlankOutline'"
                                        class="w-5 h-5"
                                        :class="{
                                            'text-gray-400': selectedForDrawing.length <= 1,
                                            'text-primary-500': selectedForDrawing.length > 1 && backSurfaceFilters.offset,
                                        }"
                                    />
                                </div>

                                <div class="ml-1 truncate">
                                    {{ $t('plot_graph.back_surface_filters.offset.label') }}
                                </div>
                            </div>

                            <!-- Center/Edge Focus Filter -->
                            <div>
                                <tippy placement="bottom" :delay="500">
                                    <select
                                        v-model="backSurfaceFilters.focusArea"
                                        class="rounded border border-gray-300 px-2 py-1 bg-white select-none outline-none
                                        focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-primary-500"
                                    >
                                        <option v-for="focusArea in ['CENTER', 'EDGE']" :key="focusArea" :value="focusArea">
                                            {{ $t(`plot_graph.back_surface_filters.focus_area.values.${focusArea}.label`) }}
                                        </option>
                                    </select>

                                    <template #content>
                                        <div class="py-2 max-w-[200px] text-gray-600 text-sm">
                                            <div class="flex items-center gap-2 mb-2 text-lg text-primary-500 font-thin">
                                                <mdi-icon icon="mdiInformationOutline" class="w-4 h-4" />

                                                <div>
                                                    {{ $t(`plot_graph.back_surface_filters.focus_area.label`) }}
                                                </div>
                                            </div>

                                            <p class="mb-2">
                                                <strong class="text-gray-700">
                                                    {{ $t(`plot_graph.back_surface_filters.focus_area.values.CENTER.label`) }}
                                                </strong>
                                                <br>
                                                {{ $t(`plot_graph.back_surface_filters.focus_area.values.CENTER.help`) }}
                                            </p>

                                            <p>
                                                <strong class="text-gray-700">
                                                    {{ $t(`plot_graph.back_surface_filters.focus_area.values.EDGE.label`) }}
                                                </strong>
                                                <br>
                                                {{ $t(`plot_graph.back_surface_filters.focus_area.values.EDGE.help`) }}
                                            </p>
                                        </div>
                                    </template>
                                </tippy>
                            </div>

                            <!-- Dilation Filter -->
                            <!-- <div class="flex items-center">
                                <div class="mr-1">
                                    {{ $t('plot_graph.back_surface_filters.dilation.label') }}
                                </div>

                                <div>
                                    <select
                                        v-model="backSurfaceFilters.dilationCoeff"
                                        class="rounded border border-gray-300 px-2 py-1 bg-white select-none outline-none
                                            focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-primary-500"
                                        :class="{ 'text-primary-500': backSurfaceFilters.dilationCoeff !== 1 }"
                                    >
                                        <option v-for="coeff in dilationCoeffRange" :key="coeff" :value="coeff">
                                            {{ coeff.toFixed(1) }}
                                        </option>
                                    </select>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <!-- Visualizations -->
                    <div class="sl-graph__visualizations relative">
                        <div v-if="loadingPoints" class="absolute top-0 left-0 w-full h-[240px] flex justify-center items-center italic">
                            <img src="/img/loader-5.gif" alt="">
                        </div>

                        <!-- Chart Area -->
                        <div class="relative w-full h-[240px] resize overflow-hidden" :class="{ 'invisible': loadingPoints }">
                            <canvas id="graph-area" ref="graph-area"></canvas>
                        </div>

                        <!-- Data Tables -->
                        <template v-if="!loadingPoints">
                            <div
                                v-for="id in selectedForDrawing"
                                :key="id"
                                class="grid mt-2 rounded-md border border-l-4 border-gray-200
                                    bg-gray-50 text-gray-600 text-center text-sm overflow-hidden"
                                :class="zoneTableClass"
                                :style="{ borderLeftColor: zoneColors[id]['OPTICAL'] }"
                            >
                                <!-- Lens/Fitting Name -->
                                <div class="bg-gray-100">
                                    <span class="text-primary-500">
                                        {{ $t('common.lens') }}
                                        {{ getLensLetter(id) }}
                                    </span>
                                    ⌀
                                    <span class="text-primary-500">
                                        {{ getOriginalMeridians(id)[0]?.points[zones[id].at(-1)].at(-1).at(-1)[0].toFixed(1) }}
                                    </span>
                                    mm
                                </div>

                                <!-- Zone Names -->
                                <template
                                    v-for="zone in zones[id]"
                                    :key="zone"
                                >
                                    <div v-if="zone !== 'BEVEL'">
                                        {{ $t(`lens_zones.${zone}.label`) }}
                                    </div>
                                </template>

                                <div class="col-span-full"></div>

                                <template
                                    v-for="(meridian, j) in getOriginalMeridians(id)"
                                    :key="j"
                                >
                                    <!-- Meridian Angle & Lens/Fitting SAG -->
                                    <div
                                        class="grid gap-4 bg-gray-100 text-[11px]"
                                        :class="isAsymmetrical[id] || isQuadrant[id] ? 'grid-cols-2' : 'grid-cols-1'"
                                    >
                                        <div v-if="isAsymmetrical[id] || isQuadrant[id]" class="text-right">
                                            <template v-if="isAsymmetrical[id]">
                                                <span class="text-primary-500">
                                                    {{ $t(`plot_graph.angle_row.${j}.label`) }}
                                                </span>
                                            </template>

                                            <template v-else>
                                                <span class="text-primary-500">
                                                    {{ meridian.angle }}
                                                </span>°
                                            </template>
                                        </div>

                                        <div :class="isAsymmetrical[id] || isQuadrant[id] ? 'text-left' : 'text-center'">
                                            <span class="text-primary-500">
                                                {{ meridian.points[zones[id].at(-1)].at(-1).at(-1)[1].toFixed(0) }}
                                            </span>
                                            µm
                                        </div>
                                    </div>

                                    <div
                                        v-for="zone in zones[id].filter(zone => zone !== 'BEVEL')"
                                        :key="zone"
                                        class="sl-graph__cell"
                                        :class="{ 'sl-graph__cell--last-row': j === getOriginalMeridians(id).length - 1 }"
                                    >
                                        <!-- Diameter -->
                                        <div
                                            v-if="!ANGLE_ZONES.includes(zone)"
                                            class="text-right"
                                        >
                                            ⌀
                                            <span class="text-primary-500">
                                                {{ getOriginalMeridians(id)[0]?.points[zone].at(-1).at(-1)[0].toFixed(1) }}
                                            </span>
                                            mm
                                        </div>

                                        <!-- Angle -->
                                        <div
                                            v-if="ANGLE_ZONES.includes(zone)"
                                            class="col-span-2 text-center"
                                        >
                                            <span class="text-primary-500">{{ getZoneAngle(meridian, zone).toFixed(0) }}</span>°
                                        </div>

                                        <!-- Eccentricity -->
                                        <div
                                            v-else-if="ECCENTRICITY_ZONES.includes(zone)"
                                            class="text-left"
                                        >
                                            e <span class="text-primary-500">{{ getZoneEccentricity(meridian, zone).toFixed(2) }}</span>
                                        </div>

                                        <!-- Sagittal -->
                                        <div
                                            v-else
                                            class="text-left"
                                        >
                                            SAG
                                            <span class="text-primary-500">{{ getLastPoint(meridian, zone)[1].toFixed(0) }}</span>
                                            µm
                                        </div>
                                    </div>

                                    <div class="col-span-full"></div>
                                </template>
                            </div>
                        </template>

                        <!-- Meridian Selector -->
                        <div class="mt-4">
                            <div class="flex justify-center items-center gap-4">
                                <button
                                    class="lsn-btn"
                                    :class="isMoving
                                        ? ['lsn-btn--gray', 'lsn-btn--disabled']
                                        : 'lsn-btn--primary'"
                                    :disabled="isMoving"
                                    @click="moveToAngle(flatAngle)"
                                >
                                    <mdi-icon
                                        icon="mdiCircleOffOutline"
                                        class="mr-2 w-4 h-4 -rotate-[45deg]"
                                    />

                                    {{ $t('plot_graph.flat_angle_button.label') }}
                                </button>

                                <button
                                    class="lsn-btn"
                                    :class="isMoving
                                        ? ['lsn-btn--gray', 'lsn-btn--disabled']
                                        : 'lsn-btn--primary'"
                                    :disabled="isMoving"
                                    @click="moveToAngle(steepAngle)"
                                >
                                    <mdi-icon
                                        icon="mdiCircleOffOutline"
                                        class="mr-2 w-4 h-4 rotate-[45deg]"
                                    />

                                    {{ $t('plot_graph.steep_angle_button.label') }}
                                </button>

                                <div
                                    class="flex justify-center items-center rounded border pl-0.5 pr-4 py-0.5 bg-white"
                                    :class="isMoving
                                        ? ['cursor-not-allowed', 'border-gray-300', 'text-gray-300']
                                        : ['cursor-pointer', 'border-primary-500', 'text-primary-500']"
                                    @click="$refs.angleInput.focus(); $refs.angleInput.select();"
                                >
                                    <div>
                                        <input
                                            ref="angleInput"
                                            v-model="angle"
                                            type="number"
                                            min="0"
                                            max="359"
                                            step="1"
                                            class="rounded pl-2 py-0.5 w-[60px] text-center outline-none"
                                            :class="isMoving ? 'cursor-not-allowed' : ''"
                                            :disabled="isMoving"
                                        >
                                    </div>

                                    <div class="mx-2 select-none">
                                        &harr;
                                    </div>

                                    <div class="select-none">
                                        {{ getOppositeAngle(angle) }}°
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </lsn-sider>
        </teleport>
    </div>
</template>


<!-- /////////////////////////////////////////////////////////////////////////// SCRIPT -->

<script>
/**
 * @typedef {[x: number, y: number]} Point
 * @typedef {Point[]} Segment
 * @typedef {{angle: number, points: Object.<string, Segment[]>, calculated?: boolean}} Meridian
 */

import { shallowRef } from 'vue';
import Chart from 'chart.js/auto';
import debounce from 'lodash-es/debounce';
import AbstractField from '../AbstractField.vue';
import axios from '@/axios';
import Numbers from '@/utils/Numbers';
import { deg2rad, exSRDbis, rad2deg, radiusDeltaSagEx } from '@/utils/Formulas';
import useFittingComposable from '@/composables/FittingComposable';
import OffsetFilter from '@/views/graph/surface-filters/OffsetFilter';
import DilationFilter from '@/views/graph/surface-filters/DilationFilter';

const SCALE_PADDING_X = 0.25;
const SCALE_PADDING_Y = 250;
const MARKER_SIZE = 0.075;
const ANGLE_ZONES = [
    'SCLERAL',
];
const ECCENTRICITY_ZONES = [
    'PERIPHERAL',
    'CORNEAL',
    'ALIGNMENT',
];

let oldSelection = [];

export default
{
    name: 'PlotGraphButton',

    extends: AbstractField,

    setup()
    {
        // Data
        /** @type {{}|null} */
        const graph = null;

        /** @type {Meridian[][]} */
        const backSurfacePoints = shallowRef([]);

        // Composables
        const {
            getLensLetter,
            toggleDrawing,
        } = useFittingComposable();

        return {
            ANGLE_ZONES,
            ECCENTRICITY_ZONES,

            graph,
            backSurfacePoints,

            getLensLetter,
            toggleDrawing,
        };
    },

    data()
    {
        return {
            loadingPoints: false,
            graphOpen:     false,
            angle:         0,
            isMoving:      false,
            fittingColors: [],

            /** Hack to refresh computed `shallowRef`. */
            refresh: 0,

            backSurfaceFilters:
            {
                /** @type {"CENTER"|"EDGE"} How to align the lenses in the graph. */
                focusArea:     'CENTER',
                /** Whether the offset filter is enabled. */
                offset:        false,
                dilationCoeff: 1,
            },
        };
    },

    computed: {
        fittings()
        {
            return this.$store.state.lensOrder.fittings;
        },

        /**
         * The IDs selected for drawing.
         *
         * @returns {number[]}
         */
        selectedForDrawing()
        {
            return this.fittings.draw[this.side];
        },

        /**
         * The curves to be drawn in the graph area.
         *
         * @returns {Object.<string, Point[]>[]}
         */
        backSurface()
        {
            this.refresh; // hack to force refresh shallowRef

            const angle = this.angle;
            const oppositeAngle = this.getOppositeAngle(angle);

            if(!this.backSurfacePoints.length)
            {
                return [];
            }

            const out = [];
            for(const id of this.selectedForDrawing)
            {
                const rightMeridian = this.getMeridian(id, angle);
                const leftMeridian = this.getMeridian(id, oppositeAngle);
                if(rightMeridian === null || leftMeridian === null)
                {
                    out[id] = {};
                    console.warn(`Skipped lens ${this.getLensLetter(id)} because meridian was not found`, { rightMeridian, leftMeridian });
                    continue;
                }

                const values = {};
                for(const zoneName in rightMeridian.points)
                {
                    const positiveValues = rightMeridian.points[zoneName]
                        .flatMap(segment => segment.map(([x, y]) => [x, -y]));
                    const negativeValues = leftMeridian.points[zoneName]
                        .flatMap(segment => segment.map(([x, y]) => [-x, -y]));

                    values[zoneName] = negativeValues.reverse()
                        .concat([[null, null]]) // add a gap
                        .concat(positiveValues);
                }

                out[id] = values;
            }

            return out;
        },

        /**
         * The names of the zones for each fitting.
         *
         * @returns {string[][]}
         */
        zones()
        {
            this.refresh; // hack to force refresh shallowRef

            const out = [];
            for(const id of this.selectedForDrawing)
            {
                out[id] = Object.keys(this.backSurfacePoints[id]?.[0]?.points ?? {});
            }

            return out;
        },

        isAsymmetrical()
        {
            this.refresh; // hack to force refresh shallowRef

            const out = [];
            for(const id of this.selectedForDrawing)
            {
                out[id] = this.getOriginalMeridians(id).length === 2;
            }

            return out;
        },

        isQuadrant()
        {
            this.refresh; // hack to force refresh shallowRef

            const out = [];
            for(const id of this.selectedForDrawing)
            {
                out[id] = this.getOriginalMeridians(id).length === 4;
            }

            return out;
        },

        /**
         * The CSS class for the zone table, for each fitting.
         *
         * @returns {Object.<string, boolean>[]}
         */
        zoneTableClass()
        {
            this.refresh; // hack to force refresh shallowRef

            const out = [];
            for(const id of this.selectedForDrawing)
            {
                let nbZones = this.zones[id].length;
                if(this.zones[id].at(-1) !== 'BEVEL')
                {
                    nbZones++;
                }

                // Tailwind classes must be written in full for building
                out[id] = {
                    'hidden':      nbZones < 2,
                    'grid-cols-2': nbZones === 2,
                    'grid-cols-3': nbZones === 3,
                    'grid-cols-4': nbZones === 4,
                    'grid-cols-5': nbZones === 5,
                };
            }

            return out;
        },

        /**
         * The color codes for each zone, for each fitting.
         *
         * @returns {Object.<string, string>[]}
         */
        zoneColors()
        {
            const out = [];
            for(const id of this.selectedForDrawing)
            {
                const colors = {};
                for(const zone of this.zones[id])
                {
                    colors[zone] = this.fittingColors[id];
                }

                out[id] = colors;
            }

            return out;
        },

        /**
         * The current original back surface meridians.
         *
         * @returns {Meridian[]}
         */
        cOriginalBackSurfaceMeridians()
        {
            this.refresh; // hack to force refresh shallowRef

            const id = this.$store.state.lensOrder.fittings.current[this.side];
            const meridians = this.backSurfacePoints[id] ?? [];
            return meridians.filter(m => !m.calculated);
        },

        /**
         * The angle of the first meridian of the currently selected fitting.
         * Defaults to `0` if the meridian information is unavailable.
         *
         * @returns {number}
         */
        flatAngle()
        {
            return this.cOriginalBackSurfaceMeridians[0]?.angle ?? 0;
        },

        /**
         * The angle of the second meridian of the currently selected fitting.
         * Defaults to `90` if the meridian information is unavailable.
         *
         * @returns {number}
         */
        steepAngle()
        {
            return this.cOriginalBackSurfaceMeridians[1]?.angle ?? 90;
        },

        /**
         * The options of the dilation filter.
         *
         * @returns {number[]}
         */
        dilationCoeffRange()
        {
            const out = [];
            for(let i = 3; i <= 30; i += 1)
            {
                out.push(i / 10);
            }

            return out;
        },
    },

    watch:
    {
        backSurface:
        {
            handler()
            {
                if(this.graph === null)
                {
                    return;
                }

                // Rebuild datasets
                this.graph.data.datasets = [];
                const markers = [];
                for(const id of this.selectedForDrawing)
                {
                    const zoneNames = Object.keys(this.backSurface[id]);
                    for(const zoneName of zoneNames)
                    {
                        const label = this.$t(`lens_zones.${zoneName}.label`);
                        const data = this.applyBackSurfaceFilters(id, zoneName);

                        // Build the zone's dataset
                        this.graph.data.datasets.unshift({
                            label,
                            borderColor:     this.zoneColors[id][zoneName],
                            backgroundColor: this.zoneColors[id][zoneName],
                            pointRadius:     0,
                            pointHitRadius:  0,
                            showLine:        true,
                            borderJoinStyle: 'bevel',
                            borderWidth:     1,
                            data,
                        });

                        // Add a marker at the beginning & end of each zone
                        const firstPoint = data[0];
                        const lastPoint  = data.at(-1);
                        const size = this.backSurface[id][Object.keys(this.backSurface[id]).at(-1)].at(-1)[1] * MARKER_SIZE;
                        markers.push(
                            ...this.getMarkerData(firstPoint, size, label),
                            ...this.getMarkerData(lastPoint, size, label)
                        );
                    }
                }

                const markerColor = '#666';
                this.graph.data.datasets.unshift({
                    code:            'MARKERS', // custom ID
                    borderColor:     markerColor,
                    backgroundColor: markerColor,
                    pointRadius:     0,
                    pointHitRadius:  2.5,
                    showLine:        true,
                    borderWidth:     1,
                    data:            markers,
                });

                // Update the display
                this.$nextTick(() =>
                {
                    this.graph.update();
                    this.updateGraphScales();
                });
            },
            deep: true,
        },

        selectedForDrawing:
        {
            async handler(newSelection)
            {
                // Identify newly selected fittings
                const newIds = [];
                for(const newId of newSelection)
                {
                    if(!oldSelection.includes(newId))
                    {
                        newIds.push(newId);
                    }
                }
                newIds.sort();

                // Reset offset filter if necessary
                if(newSelection.length <= 1)
                {
                    this.backSurfaceFilters.offset = false;
                }

                // Recalculate newly selected fittings
                if(newIds.length && this.refresh > 0)
                {
                    await this.onSelectedForDrawing(newIds); // @fixme
                }

                // Update the old selection after handling
                oldSelection = newSelection.slice();
            },
            deep:      true,
            immediate: true,
        },

        backSurfaceFilters:
        {
            handler()
            {
                this.refresh++;
            },
            deep: true,
        },
    },

    created()
    {
        this.fittingColors = this.getAllFittingColors();

        // this.updateGraphScales = debounce(this.updateGraphScales, 50, { maxWait: 200 });
    },

    updated()
    {
        if(!this.graph && this.$refs['graph-area'])
        {
            this.angle = 0;
            this.initGraph(this.$refs['graph-area']);
        }
        else if(this.graph && !this.$refs['graph-area'])
        {
            // Properly dispose of the graph
            this.graph.destroy();
            this.graph = null;
        }
    },

    methods:
    {
        openGraph()
        {
            // Open the chart window
            this.graphOpen = true;

            if(this.loadingPoints)
            {
                return;
            }

            // Ensure current fitting is selected for drawing
            this.$store.dispatch('lensOrder/selectForDrawing', { side: this.side })
                .then(() =>
                {
                    this.onSelectedForDrawing();
                });
        },

        closeGraph()
        {
            this.graphOpen = false;
        },

        initGraph(ctx)
        {
            // Initialize the graph instance
            if(!ctx)
            {
                console.warn('Graph area is unavailable!');
                return;
            }

            const graphOptions = {
                type: 'scatter',
                options:
                {
                    animation:           false,
                    responsive:          true,
                    maintainAspectRatio: false,
                    plugins:
                    {
                        legend:
                        {
                            display: false,
                        },
                        tooltip:
                        {
                            callbacks:
                            {
                                label: (tooltipItem, data) =>
                                {
                                    const dataset = this.graph?.data.datasets[tooltipItem.datasetIndex];
                                    if(!dataset)
                                    {
                                        return;
                                    }

                                    // Handle markers dataset separately
                                    if(dataset.code === 'MARKERS')
                                    {
                                        const label = dataset.data[tooltipItem.dataIndex].label;
                                        if(!label)
                                        {
                                            return;
                                        }

                                        return label;
                                    }

                                    return `${dataset.label} ${tooltipItem.formattedValue}`;
                                },
                            },
                        },
                    },
                },
                data:
                {
                    datasets: [],
                },
            };
            this.graph = new Chart(ctx, graphOptions);
        },

        async onSelectedForDrawing(ids = [])
        {
            if(!ids.length)
            {
                ids = this.selectedForDrawing;
            }

            // Calculate every selected fitting
            for(const id of ids)
            {
                const cPrototype = this.$store.getters['lensOrder/getPrototype'](this.side, id);

                // Load up the points
                this.loadingPoints = true;
                const url = '/api/prototype/calculate-points/:eid/:prototypeCode/:surfaceName'
                    .replace(':eid', this.$store.state.account.cEntity.id)
                    .replace(':prototypeCode', cPrototype.code)
                    .replace(':surfaceName', 'back');
                const data = {
                    values: cPrototype.getValues(),
                    options:
                    {
                        resolution: 0.01,
                        y_offset:   0.0,
                    },
                };
                axios.post(url, data)
                    .then(({ data: meridians }) =>
                    {
                        this.onMeridiansReceived(id, meridians);
                    })
                    .catch(error =>
                    {
                        // todo: handle error
                    })
                    .then(() =>
                    {
                        this.loadingPoints = false;
                    });
            }
        },

        /**
         * Handler for when meridians are received from the server.
         *
         * @param {number} id The ID of the fitting.
         * @param {Meridian[]} meridians
         */
        onMeridiansReceived(id, meridians)
        {
            // Display adjustments:
            // Diameters: Double all X values
            // Sagittals: 1000x all Y values
            for(const meridian of meridians)
            {
                for(const zoneName in meridian.points)
                {
                    const zone = meridian.points[zoneName];
                    for(const segment of zone)
                    {
                        for(const point of segment)
                        {
                            point[0] *= 2;
                            point[1] *= 1000;
                        }
                    }
                }
            }

            const out = meridians.slice();

            // Copy angles to their opposites to make full-width meridians
            for(const meridian of meridians)
            {
                const oppositeAngle = this.getOppositeAngle(meridian.angle);
                const oppositeMeridianIndex = out.findIndex(m => m.angle === oppositeAngle);
                if(oppositeMeridianIndex !== -1)
                {
                    // The opposite meridian exists; don't recalculate.
                    continue;
                }

                // The opposite meridian doesn't exist;
                // mirror its X values, but keep the Y values.
                const oppositeMeridian = {
                    angle:      oppositeAngle,
                    points:     meridian.points,
                    calculated: true,
                };

                const meridianIndex = out.findIndex(m => m.angle === meridian.angle);
                out.splice(meridianIndex + 1, 0, oppositeMeridian);
            }

            this.backSurfacePoints[id] = out;

            // Force refresh computed on shallowRef
            this.refresh++;
        },

        /**
         * Calculate the angle that is 180° from `angle`.
         *
         * @param {number} angle
         * @returns {number}
         */
        getOppositeAngle(angle)
        {
            return (angle + 180) % 360;
        },

        /**
         * Get the meridian at `angle`.
         *
         * @param {number} id
         * @param {number} angle
         * @returns {Meridian?}
         */
        getMeridian(id, angle)
        {
            if(typeof id === 'undefined')
            {
                throw new TypeError('getMeridian: id is undefined');
            }

            if(isNaN(angle))
            {
                throw new TypeError('getMeridian: angle is NaN');
            }

            if(angle % 1 !== 0)
            {
                const orig = angle;
                angle = Math.round(angle);
                console.warn(`getMeridian: angle was silently converted from ${orig.toFixed(2)} to ${angle}`);
            }

            if(!this.backSurfacePoints[id]?.length)
            {
                return null;
            }

            const meridianIndex = this.backSurfacePoints[id].findIndex(m => m.angle === angle);
            if(meridianIndex !== -1)
            {
                // The meridian exists;
                // just return it.
                return this.backSurfacePoints[id][meridianIndex];
            }

            // The meridian doesn't exist;
            // find the closest two angles and interpolate the values
            // of each of their points.
            const prev = this.getPreviousMeridian(id, angle);
            const next = this.getNextMeridian(id, angle);
            if(prev === null || next === null)
            {
                return null;
            }

            const alpha = deg2rad(Math.abs(angle - prev.angle));
            const meridian = {
                angle,
                points: {},
            };

            for(const zoneName in prev.points)
            {
                const prevZone = prev.points[zoneName];
                const nextZone = next.points[zoneName];

                meridian.points[zoneName] = [];
                for(let s = 0; s < prevZone.length; s++) // "s" is for "segment"
                {
                    const prevPoints = prevZone[s];
                    const nextPoints = nextZone[s];

                    const segment = [];
                    for(let i = 0; i < prevPoints.length; i++)
                    {
                        // px: diameter at previous angle
                        // py: sagittal at previous angle
                        // nx: diameter at next angle
                        // ny: sagittal at next angle
                        const [px, py] = prevPoints[i];
                        const [nx, ny] = nextPoints[i];
                        if(px !== nx)
                        {
                            throw new Error(`Previous and next diameters should be the same! prev=(dia=${px}, sag=${py}), next=(dia=${nx}, sag=${ny})`);
                        }

                        segment.push([
                            px,
                            // fixme: this Y-interpolation formula only works if angles are 90° apart
                            py * (Math.cos(alpha) ** 2) + ny * (Math.sin(alpha) ** 2),
                        ]);
                    }

                    meridian.points[zoneName].push(segment);
                }
            }

            return meridian;
        },

        /**
         * Get the first meridian before the one at `angle`.
         *
         * @param {number} id
         * @param {number} angle
         * @returns {Meridian?}
         */
        getPreviousMeridian(id, angle)
        {
            if(typeof id === 'undefined ')
            {
                throw new TypeError('getMeridian: id is undefined');
            }

            if(!this.backSurfacePoints[id]?.length)
            {
                return null;
            }

            angle %= 360;
            let currentAngle = angle;
            if(currentAngle < 0)
            {
                currentAngle += 360;
            }

            for(;;)
            {
                const meridian = this.backSurfacePoints[id].find(m => m.angle === currentAngle);
                if(meridian)
                {
                    return meridian;
                }

                currentAngle -= 1;
                if(currentAngle < 0)
                {
                    currentAngle += 360;
                }
            }
        },

        /**
         * Get the first meridian after the one at `angle`.
         *
         * @param {number} id
         * @param {number} angle
         * @returns {Meridian?}
         */
        getNextMeridian(id, angle)
        {
            if(typeof id === 'undefined ')
            {
                throw new TypeError('getMeridian: id is undefined');
            }

            if(!this.backSurfacePoints[id]?.length)
            {
                return null;
            }

            angle %= 360;
            let currentAngle = angle;
            if(currentAngle < 0)
            {
                currentAngle += 360;
            }

            for(;;)
            {
                const meridian = this.backSurfacePoints[id].find(m => m.angle === currentAngle);
                if(meridian)
                {
                    return meridian;
                }

                currentAngle += 1;
                if(currentAngle >= 360)
                {
                    currentAngle -= 360;
                }
            }
        },

        /**
         * Get a flat array of all the points of a meridian.
         *
         * @param {number} id
         * @param {number} angle
         * @returns {Point[]}
         */
        getFlatMeridianPoints(id, angle)
        {
            const meridian = this.getMeridian(id, angle);
            if(!meridian)
            {
                return [];
            }

            return Object.keys(meridian.points)
                .reduce(
                    (acc, zone) => [
                        ...acc,
                        ...meridian.points[zone].flatMap(segment => segment),
                    ],
                    []
                );
        },

        /**
         * Smoothly change the viewing angle.
         *
         * @param {Number} targetAngle The target angle in degrees (integer).
         * @param {Number} duration The duration, in milliseconds (integer).
         */
        async moveToAngle(targetAngle, duration = 500)
        {
            if(this.isMoving)
            {
                return;
            }

            targetAngle = (360 + (Math.round(targetAngle) % 360)) % 360;
            if(targetAngle === this.angle)
            {
                return;
            }

            duration = Math.trunc(Math.abs(duration));
            if(duration <= 0)
            {
                return;
            }

            this.isMoving = true;

            const diff = targetAngle - this.angle;
            const stepMs = 33; // ~30fps
            const stepAngle = Math.round(diff / (duration / stepMs)) || Math.sign(diff);

            const t0 = Date.now();
            while(this.angle !== targetAngle)
            {
                await new Promise(resolve =>
                {
                    setTimeout(resolve, stepMs);
                });

                this.angle += stepAngle;
                if(
                    (diff > 0) && (this.angle >= targetAngle) ||
                    (diff < 0) && (this.angle <= targetAngle)
                )
                {
                    this.angle = targetAngle;
                    this.isMoving = false;
                }
            }
        },

        /**
         * Get the last point of a zone of a meridian.
         *
         * @param {Meridian} meridian
         * @param {string} zone
         * @returns {Point|undefined}
         */
        getLastPoint(meridian, zone)
        {
            return meridian.points[zone].at(-1).at(-1);
        },

        /**
         * Calculate the angle of a zone in degrees.
         *
         * @param {Meridian} meridian
         * @param {string} zone
         * @returns {number}
         */
        getZoneAngle(meridian, zone)
        {
            const [dia1, sag1] = meridian.points[zone][0][0];
            const [dia2, sag2] = meridian.points[zone].at(-1).at(-1);

            const deltaDia = dia2 - dia1;
            const deltaSag = (sag2 - sag1) / 1000;

            return rad2deg(Math.atan(deltaSag / (deltaDia / 2)));
        },

        /**
         * Calculate the eccentricity of a zone.
         *
         * @param {Meridian} meridian
         * @param {string} zone
         * @returns {number}
         */
        getZoneEccentricity(meridian, zone)
        {
            const s1p = meridian.points['OPTICAL'][0].at(-1);
            const r0 = radiusDeltaSagEx(s1p[1] / 1000, 0, 0, s1p[0]);

            const [dia, sag] = meridian.points[zone].at(-1).at(-1);

            return exSRDbis(r0, sag / 1000, dia);
        },

        /**
         * Get computed background colors for drawings.
         *
         * @returns {string[]}
         */
        getAllFittingColors()
        {
            const colors = [];
            for(let i = 0; i <= 6; i++)
            {
                // Create a hidden div with the color class
                const div = document.createElement('div');
                const className = `sl-fitting-selector__color--${i}`;
                div.classList.add(className);
                div.style.display = 'none';
                document.body.appendChild(div);

                // Retrieve its color and convert from RGB to HSL
                const bgColor = getComputedStyle(div).backgroundColor;
                colors.push(bgColor);

                // Delete the div (cleanup)
                div.remove();
            }

            return colors;
        },

        /**
         * Get the list of meridians that weren't calculated on the client.
         *
         * @param {number} id The fitting ID.
         * @returns {Meridian[]}
         */
        getOriginalMeridians(id)
        {
            return this.backSurfacePoints[id]?.filter(m => !m.calculated) ?? [];
        },

        /**
         * Get the data for a dataset displaying a vertical bar.
         *
         * @param {Point}   point The point on which the marker should be centered.
         * @param {number}  size  The size of the marker.
         * @param {?string} label The label for the marker.
         */
        getMarkerData(point, size, label = null)
        {
            return [
                { x: point[0], y: point[1] + (size / 2), label },
                { x: point[0], y: point[1], label },
                { x: point[0], y: point[1] - (size / 2), label },
                { x: null, y: null }, // disconnect
            ];
        },

        /**
         * Apply transformations ("filters") to the back surface for display.
         *
         * @param {number} id   The ID of the fitting.
         * @param {string} zone The code of the zone to handle.
         * @returns {Point[]} The points of the specified zone after applying the transformations.
         */
        applyBackSurfaceFilters(id, zone)
        {
            const surface = this.backSurface[id];
            const angle = this.angle;

            /** @type {string} */
            const lastZoneName = Object.keys(surface).at(-1);
            const clSag = surface[lastZoneName].at(-1)[1];

            const filters = [];

            // When there's no cornea, allow offsetting lenses by the delta
            // between their respective SAGs and that of lens A.
            // Offset = maxSag(currentLens) - maxSag(lensA)
            const hasCornea = false; // @todo currently there never is a cornea
            if(this.backSurfaceFilters.focusArea === 'CENTER' && !hasCornea)
            {
                const getMaxSag = id => Math.round(Math.max(
                    ...this.getFlatMeridianPoints(id, angle).map(([, y]) => y)
                ));

                filters.push(new OffsetFilter({
                    zone,
                    offset: getMaxSag(id) - getMaxSag(1),
                }));
            }

            // Offset the lenses vertically.
            if(this.backSurfaceFilters.offset)
            {
                filters.push(new OffsetFilter({
                    zone,
                    offset: Math.abs(0.05 * clSag * (id - 1)), // offset by 5% of the total sag
                }));
            }

            // Exaggerate the difference in height of each sag.
            if(this.backSurfaceFilters.dilationCoeff !== 1)
            {
                /** @type {Point} The last point of the first segment. */
                const s1p = this.getMeridian(id, angle).points['OPTICAL'][0].at(-1);
                const r0 = radiusDeltaSagEx(s1p[1] / 1000, 0, 0, s1p[0]);
                filters.push(new DilationFilter({
                    zone,
                    r0,
                    coeff: this.backSurfaceFilters.dilationCoeff,
                }));
            }

            return filters.reduce((acc, f) => f.run(acc), surface[zone]);
        },

        toggleBackSurfaceOffset()
        {
            if(this.selectedForDrawing.length <= 1)
            {
                return;
            }

            this.backSurfaceFilters.offset = !this.backSurfaceFilters.offset;
        },

        /**
         * Update the graph scales using the latest data for the current angle.
         */
        updateGraphScales()
        {
            this.graph.options.scales = this.calculateGraphScales();
            this.graph.update();
        },

        /**
         * The scales configuration for the chart area.
         *
         * @returns {{}}
         */
        calculateGraphScales()
        {
            // Approximative default values for when there's no data
            const scales = {
                x: {
                    min: -4,
                    max: +4,
                },
                y: {
                    min: -250,
                    max: +250,
                },
            };

            if(!this.backSurface.length)
            {
                return scales;
            }

            const allPoints = this.graph.data.datasets
                .filter(dataset => dataset.code !== 'MARKERS')
                .flatMap(dataset => dataset.data);

            [
                scales.x.min,
                scales.y.min,
                scales.x.max,
                scales.y.max,
            ] = allPoints.reduce(([minX, minY, maxX, maxY], [x, y]) => [
                Math.min(minX, x),
                Math.min(minY, y),
                Math.max(maxX, x),
                Math.max(maxY, y),
            ], [0, 0, 0, 0]);

            scales.x.min = Numbers.floorToMultiple(scales.x.min - SCALE_PADDING_X, SCALE_PADDING_X);
            scales.x.max = Numbers.ceilToMultiple(scales.x.max + SCALE_PADDING_X, SCALE_PADDING_X);
            scales.y.min = Numbers.floorToMultiple(scales.y.min - (SCALE_PADDING_Y / 5), SCALE_PADDING_Y);
            scales.y.max = Numbers.ceilToMultiple(scales.y.max + (SCALE_PADDING_Y / 5), SCALE_PADDING_Y);

            scales.x.ticks = {
                beginAtZero: true,
                stepSize:    2,
                precision:   1,
                callback(value, index, tick)
                {
                    return value === 0 ? '0' : value.toFixed(1);
                },
            };
            scales.y.ticks = {
                beginAtZero: true,
                stepSize:    SCALE_PADDING_Y,
                precision:   0,
                callback(value, index, tick)
                {
                    if(value > 0) return '';
                    return (-value).toFixed(0);
                },
            };

            return scales;
        },
    },
};
</script>


<!-- /////////////////////////////////////////////////////////////////////////// STYLE -->

<style lang="scss" scoped>
.sl-graph
{
    @apply h-screen overflow-hidden text-gray-500;

    // 256px = form label width on desktop
    // 8px   = approximative scrollbar width
    width: calc(50vw - (256px / 2) - 8px);
}

.sl-graph__content
{
    @apply relative p-8 w-full h-full overflow-auto;
}

.sl-graph__close
{
    @apply cursor-pointer fixed top-8 w-8 h-8 transition
        hover:text-primary-500;
}

.sl-graph__close--left
{
    @apply left-8;
}

.sl-graph__close--right
{
    @apply right-8;
}

// Fitting Selector
.sl-graph__fittings
{
    @apply flex justify-start items-center grow shrink gap-4 rounded-md p-2 bg-gray-100 truncate;
}

// Filters
.sl-graph__filters
{
    @apply flex justify-end items-center grow shrink gap-4 rounded-md p-2 bg-gray-100 text-sm;
}

// Button in the graph window
.sl-graph .lsn-btn
{
    @apply rounded border-primary-600 bg-primary-600 text-white
        hover:bg-primary-500 hover:border-primary-500
        focus-visible:bg-primary-500 focus-visible:border-primary-500
        active:bg-primary-700 active:border-primary-700;


    &:disabled
    {
        @apply cursor-not-allowed border-gray-300 bg-gray-300 text-gray-400;
    }
}

.sl-graph__cell
{
    @apply grid grid-cols-2 gap-4 border-b border-gray-100 bg-white text-[11px];
}

.sl-graph__cell--last-row
{
    @apply border-b-0;
}

// Graph Area
#graph-area
{
    @apply cursor-crosshair;
}
</style>

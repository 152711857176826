<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="sl-opti" :class="componentClass">
        <div class="sl-opti__banner">
            <img :src="bannerImageUrl" />

            <img src="/img/logo-swisslens-white.svg" class="h-[20px] mt-8 opacity-80" />
        </div>

        <div class="sl-opti__cta">
            <div>
                <div class="sl-opti__heading" v-html="t('opti_banner.heading_text')"></div>

                <div class="sl-opti__body" v-html="t('opti_banner.body_text', { year: YEAR })"></div>
            </div>

            <button
                class="sl-opti__button"
                :class="{ 'sl-opti__button--disabled': addingToBasket }"
                @click="addToBasket"
            >
                {{ t('opti_banner.button_text') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import useOptiMunichPromotionComposable from '@/composables/OptiMunichPromotionComposable';


// ------------------------------------------------------------ COMPOSABLES

const store  = useStore();
const router = useRouter();
const route  = useRoute();
const { t }  = useI18n({ useScope: 'global' });
const {
    success: toastSuccess,
    error:   toastError,
} = useToast();
const { ARTICLE_CODE, YEAR } = useOptiMunichPromotionComposable();


// ------------------------------------------------------------ PROPS

/**
 * @type {{format: "spread"|"stacked"}}
 */
const props = defineProps({
    format:
    {
        type:      String,
        required:  true,
        validator: value => ['spread', 'stacked'].includes(value),
    },
});


// ------------------------------------------------------------ DATA

const addingToBasket = ref(false);


// ------------------------------------------------------------ COMPUTED

const componentClass = computed(() => ({
    'sl-opti--spread':  props.format === 'spread',
    'sl-opti--stacked': props.format === 'stacked',
}));

const bannerImageUrl = computed(() =>
{
    switch(route.params.lang)
    {
        case 'de':
            return '/img/basket/promotions/sl-opti-banner-2025-de.jpg';

        default:
            return '/img/basket/promotions/sl-opti-banner-2025-en.jpg';
    }
});


// ------------------------------------------------------------ METHODS

function addToBasket()
{
    // Only add to basket one piece at a time
    if(addingToBasket.value)
    {
        return;
    }

    addingToBasket.value = true;

    const basketLine = {
        article_code: ARTICLE_CODE,
        quantity:     1,
        data:         null,
    };

    store.dispatch('basket/saveLines', basketLine)
        .then(lines =>
        {
            // Redirect to basket
            if(route.name !== 'basket')
            {
                router.push({ name: 'basket' })
                    .then(() =>
                    {
                        // Display success notice
                        toastSuccess(t('common.basket_updated'));
                    });
            }
            else
            {
                // Display success notice
                toastSuccess(t('common.basket_updated'));
            }
        })
        .catch(error =>
        {
            // Display error
            toastError(t('common.errors.error'));
        })
        .then(() =>
        {
            addingToBasket.value = false;
        });
}
</script>

<style lang="scss" scoped>
$opti-primary: #02636e;
$opti-accent: #e0dd04;

.sl-opti
{
    @apply flex justify-between items-start gap-12 rounded-lg p-4 text-gray-100;

    background-color: $opti-primary;
}

.sl-opti--spread
{
    @apply pr-16;
}

.sl-opti--stacked
{
    @apply flex-col items-center py-8;
}

.sl-opti__banner
{
    @apply flex flex-col justify-around items-center;

    > img:first-of-type
    {
        @apply rounded-lg;
    }
}

.sl-opti__cta
{
    @apply flex flex-col justify-center items-center;

    > div
    {
        @apply mb-4 w-[320px] text-center leading-snug space-y-2;
    }

    :deep(em)
    {
        @apply not-italic;

        color: $opti-accent;
    }

    :deep(strong)
    {
        @apply text-white uppercase;
    }
}

.sl-opti__heading
{
    @apply text-xl font-thin text-white;
}

.sl-opti__body
{
    @apply text-sm;
}

button.sl-opti__button
{
    @apply rounded-md border border-[#e0dd04] px-4 py-2 bg-transparent transition-colors;

    border-color: $opti-accent;
    color: $opti-accent;

    &:not(.sl-opti__button--disabled):hover
    {
        background-color: $opti-accent;
        color: $opti-primary;
    }

    &.sl-opti__button--disabled
    {
        @apply cursor-not-allowed opacity-50;
    }
}
</style>
